<template>
  <div class="tenure-wrap">
    <div class="list" v-if="dataList.length">
      <div class="item" v-for="(item, index) in dataList" :key="index">
        <div class="left">{{ dateFormat(item.createTime) }}</div>
        <div class="right">
          {{ $t('teacher.edit') }} &nbsp;
          <!-- 聘期修改 -->
          {{ tenureType[item.tenureType] }}
          {{ item.levelName }}
          {{ dateFormat(item.startTime, "YYYY-MM-DD") }}
          <template v-if="item.endTime">
            ~{{ dateFormat(item.endTime, "YYYY-MM-DD") }}
          </template>
          {{ $t('teacher.take_effect') }}
          <!-- 生效 -->
        </div>
      </div>
    </div>
    <a-empty v-else style="padding: 60px 0" />
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { reactive, toRefs } from "vue";
import { dateFormat } from "@/utils/tools";
import { teacherTenure } from "@/api/teacher";
export default {
  setup() {
    const { t: $t } = useI18n();
    const state = reactive({
      dataList: [],
      tenureType: {
        1: $t('teacher.newly_hired'), // 新聘
        2: $t('teacher.renew'), // 续聘
        3: $t('teacher.promotion'), // 晋升
        4: $t('teacher.downgrade'), // 降级
        5: $t('teacher.fired'), // 解聘
      },
      loading: true,
    });

    teacherTenure().then((res) => {
      state.dataList = res.data || [];
    });

    return {
      dateFormat,
      ...toRefs(state),
    };
  },
};
</script>

<style lang="less" scoped>
.tenure-wrap {
  .list {
    .item {
      padding: 40px 0;
      font-size: 20px;
      line-height: 30px;
      .mixinFlex(space-between; center);
      .left {
        width: 200px;
        text-align: right;
        position: relative;
        &::before {
          content: "";
          width: 1px;
          background-color: #f0f0f0;
          position: absolute;
          right: -36px;
          top: -57px;
          bottom: -40px;
        }
      }
      .right {
        width: 870px;
        position: relative;
        &::before {
          content: "";
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-color: @color-theme;
          opacity: 0.3;
          position: absolute;
          left: -49px;
          top: 0;
        }
        &::after {
          content: "";
          width: 30px;
          height: 30px;
          border-radius: 50%;
          border: 8px solid @color-theme;
          position: absolute;
          left: -49px;
          top: 0;
        }
      }
      &:last-child {
        .left {
          &::before {
            bottom: 0;
          }
        }
      }
    }
  }
}
</style>